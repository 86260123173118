<div class="flex flex-col h-full">
  <div class="flex flex-col gap-4 bg-neutral-0 z-[100] sticky top-0 overscroll-none p-8">
    @if (iconSvg) {
      <div class="featured-icon-primary-xl">
        <span [inlineSVG]="iconSvg"></span>
      </div>
    }
    <div class="flex flex-col gap-2">
      <p class="text-[20px] font-medium text-neutral-900">{{ title }}</p>
      <p class="text-[14px] text-neutral-700">{{ description }}</p>
    </div>
  </div>

  <div class="w-full">
    <ng-content select="[alert-bar]"></ng-content>
  </div>

  <div class="h-full overflow-y-scroll py-[8px] pl-8 pr-5">
    <ng-content select="[modal-content]"></ng-content>
  </div>

  <div class="sticky bottom-0 p-8">
    <ng-content select="[modal-footer]"></ng-content>
  </div>
</div>
