import {Component, Input} from '@angular/core';
import {InlineSVGModule} from "ng-inline-svg-2";

@Component({
  selector: 'crm-generic-creat-modal',
  standalone: true,
  imports: [
    InlineSVGModule
  ],
  templateUrl: './generic-create-modal.component.html',
  styleUrl: './generic-create-modal.component.scss'
})
export class GenericCreateModalComponent {

  @Input({required: false}) iconSvg: string;
  @Input() title: string;
  @Input() description: string;

}
